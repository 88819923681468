.omnipresent-banner{
  background-color: $hel-black;
  color: $white;
  text-align: center;
  padding: 1rem;
  font-size: $hel-text-size-xl;

  a{
    position: relative;
    color: $white;
    padding: .4rem;
    text-decoration: none;

    &:hover,
    &:focus{
      text-decoration: underline;
    }

    &:focus,
    &:active{
      box-shadow: inset 0 0 0 2px $hel-white;
    }
  }

  .icon{
    position: relative;
    top: .3rem;
    width: 1.5rem;
    height: 1.5rem;
  }
}
