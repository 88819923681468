.banner{
  display: flex;
  flex-direction: column;
  padding: 2.4rem 1rem 1.5rem;

  @include breakpoint(medium){
    flex-direction: row;
    padding: 1.3rem 2.1rem .7rem;
  }

  &.banner--padded{
    padding: 1.9rem 1.4rem;

    @include breakpoint(medium){
      padding: 3.8rem 2.5rem 2.7rem;
    }
  }

  .banner__icon{
    margin-bottom: 1.2rem;
    color: $body-font-color;

    @include breakpoint(medium){
      margin-top: .8rem;
      margin-bottom: 0;
      margin-right: 1.3rem;
    }

    .icon{
      width: 4rem;
      height: 4rem;

      @include breakpoint(medium){
        width: 6.2rem;
        height: 6.2rem;
      }
    }
  }

  .banner__content{
    flex: 1;
    font-size: $hel-text-size-l;

    @include breakpoint(medium){
      padding: .8rem;
    }

    h2{
      margin-bottom: .9rem;
      font-weight: $global-weight-normal;

      @include breakpoint(medium){
        margin-bottom: .8rem;
      }
    }

    .button{
      margin-top: .8rem;
    }
  }

  .banner__aside{
    margin-top: .5rem;

    @include breakpoint(medium){
      margin-top: 0;
      padding-right: 1.9rem;
    }
  }
}
