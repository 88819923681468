.icons-list{
  list-style-type: none;
  margin: 2.05rem 0 0 .8rem;
  font-size: $hel-text-size-l;

  li{
    display: flex;
    align-items: center;
    padding: 0;
    margin-top: 1.55rem;

    &:first-child{
      margin-top: 0;
    }

    span{
      flex: 1;
      text-decoration: underline;
      text-underline-offset: 4px;
    }

    .icon{
      position: relative;
      color: $hel-coat;
      width: 2.2rem;
      height: 2.2rem;
      margin-right: 1.1rem;
    }
  }
}

.highlights-list{
  list-style-type: none;
  margin: 2.7rem 2.4rem 2.7rem .7rem;
  font-size: $hel-text-size-l;

  li{
    margin-top: 2.85rem;

    &:first-child{
      margin-top: 0;
    }
  }
}

ol.highlights-list{
  counter-reset: li;

  li{
    counter-increment: li;
    display: flex;
    align-items: center;

    &::before{
      content: counter(li) ".";
      display: block;
      align-self: flex-start;
      width: 1em;
      margin-right: .625rem;
      color: $hel-coat;
      text-align: center;
      font-size: 3rem;
      line-height: 1.1;
    }

    span{
      flex: 1;
    }
  }
}

.numbers-list{
  display: flex;
  flex-direction: column;
  align-self: center;
  list-style-type: none;
  margin: 0;
  padding: .8rem 0;
  width: 100%;

  @include breakpoint(smallmedium){
    width: 70%;
  }

  @include breakpoint(medium){
    width: 100%;
  }
}

.bubbles-list{
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1.5rem 1rem;
  gap: 1.5rem;

  @include breakpoint(xlarge){
    gap: .8rem;
  }

  .bubble{
    flex: 0;
    width: 70%;
    align-self: flex-start;

    &:nth-child(2n + 1){
      align-self: flex-end;
    }

    &:nth-child(2){
      width: 84%;
    }

    &:nth-child(3){
      width: 75%;

      @include breakpoint(medium){
        margin-right: 1rem;
      }

      @include breakpoint(large){
        margin-right: 0;
      }

      @include breakpoint(xlarge){
        margin-right: 1rem;
      }
    }

    &.bubble--comment.bubble--small{
      &:nth-child(2){
        background-color: $hel-engel-medium-light;

        .bubble__content::after{
          left: -9px;
          right: auto;
          margin-top: .6rem;
          border-width: 50px 3px 0 25px;
          border-top-color: $hel-engel-medium-light;
          transform: rotate(30deg);
        }
      }

      &:nth-child(3){
        background-color: $hel-copper-light;

        .bubble__content::after{
          right: 50%;
          border-top-color: $hel-copper-light;
          transform: rotate(-15deg) translateX(100%) translateY(15%);
        }
      }
    }
  }
}
