$bubble-side-padding: 1.5rem;

.bubble{
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 0 $bubble-side-padding .35rem;
  font-size: 1.6rem;
  line-height: 1.3;
  hyphens: auto;
  hyphenate-limit-chars: 12 8 4;
  overflow-wrap: break-word;

  &.bubble--large{
    font-size: 2.4rem;
    line-height: 1.3;
  }

  &.bubble--small{
    font-size: 1rem;
    line-height: 1.4;

    &.bubble--comment{
      background-color: $hel-fog-medium-light;
    }
  }

  &.bubble--comment{
    padding-top: .35rem;
    padding-bottom: .5rem;

    &.bubble--small{
      padding: 1.1rem 1.5rem 1.3rem;
      border-radius: .75rem;

      .bubble__content{
        padding-bottom: 0;

        &::after{
          top: 100%;
          left: auto;
          right: -9px;
          margin-top: 1rem;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 50px 3px 0 25px;
          border-color: $hel-fog-medium-light transparent transparent transparent;
          transform: rotate(-15deg);
          background-color: transparent;
          clip-path: none;
        }
      }
    }

    .bubble__content{
      position: relative;
      padding-bottom: 4.3rem;

      &::after{
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: -.45rem;
        bottom: 0;
        height: 43px;
        background-color: $primary;
        clip-path: polygon(0 0, calc(50% - 6px) 0, calc(50% + 25px) 37px, calc(50% + 17px) 0, 100% 0, 100% 2px, calc(50% + 19.5px) 2px, calc(50% + 28px) 43px, calc(50% - 7px) 2px, 0 2px);
      }
    }
  }
}
