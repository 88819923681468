.resource__main{
  margin-bottom: 4rem;

  @include breakpoint(large){
    margin-right: 2rem;
  }
}

.resource__aside{
  margin-bottom: 3rem;

  @include breakpoint(medium){
    // This keeps the actual content at the correct position by not pushing it
    // down due to the sidebar height. The sidebar DOM position is important
    // which is why the content cannot be moved to the same element as the
    // image.
    height: 0;
  }

  @include breakpoint(large){
    margin-left: 1.8rem;
  }

  &__title{
    @extend .h4;

    padding-bottom: .8rem;
    border-bottom: 1px solid $hel-silver;
  }
}

.resource__image{
  margin-bottom: 1rem;
}

.resource__tags{
  margin-bottom: 1rem;
}

.resource__actions{
  margin-top: .5rem;
  margin-bottom: 2rem;
}

.resource__content{
  margin-bottom: 3rem;

  @include breakpoint(medium){
    min-height: 20rem; // Ensure the sidebar has enough space as it has 0-height
  }

  // Standalone is without the image (i.e. legacy proposals)
  &--standalone{
    @include breakpoint(medium){
      min-height: 40rem; // Ensure the sidebar has enough space as it has 0-height
    }
  }

  // Results have longer sidebar which requires more space on them main section
  &--result{
    @include breakpoint(medium){
      min-height: 90rem; // Ensure the sidebar has enough space as it has 0-height
    }
  }
}
