@mixin custom-input($type){
  input[type="#{$type}"]{
    position: absolute;
    margin-bottom: 0;
    opacity: 0;

    &:checked{
      + label{
        &::before{
          border-color: $hel-bus;
        }

        &::after{
          content: "";
          display: block;
          position: absolute;
        }
      }
    }

    &:focus{
      + label::before{
        box-shadow: $input-shadow-focus;
      }
    }

    &:not(:disabled):hover{
      + label::before{
        border-color: $hel-grayscale-90;
      }
    }

    + label{
      position: relative;
      margin-left: 0;
      margin-bottom: .2rem;
      padding-left: 32px;
      font-weight: $global-weight-normal;

      &::before{
        content: "";
        position: absolute;
        top: 2px;
        left: 0;
        height: 24px;
        width: 24px;
        border: 2px solid $hel-grayscale-50;
      }
    }
  }
}

input,
select,
textarea{
  &:disabled{
    opacity: 1;
  }

  &:not(:disabled):hover{
    border-color: $input-border-color-hover;
  }
}

select{
  @include select-arrow;

  cursor: pointer;
  padding-right: ($form-spacing * 2.5);

  &:disabled{
    color: lighten($input-color, 30%);
  }
}

.field{
  margin: 0 0 $form-spacing;

  input,
  select,
  textarea{
    margin-bottom: 0;
  }

  .help-text,
  .form-error{
    margin-top: .2rem;
  }

  .form-error.is-visible + .help-text{
    margin-top: $form-spacing * -.5;
  }

  .field-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: ($input-font-size * unitless-calc($input-line-height)) + (get-side($input-padding, 'top') + get-side($input-padding, 'bottom')) - rem-calc(1);
    font-size: $hel-text-size-l;
    line-height: $hel-lineheight-l;
    margin-right: $hel-spacing-s;

    &.field-info-with-icon .info-modal-link{
      display: flex;
      align-items: center;
      gap: .5rem;

      span{
        flex: 1;
        line-height: $hel-lineheight-l;
      }

      .icon{
        width: 1.5rem;
        height: 1.5rem;
        margin-right: .1rem;
      }
    }
  }

  .reset-input{
    display: inline-block;
    margin-top: 1.4rem;
    font-size: $hel-text-size-l;
    line-height: $hel-lineheight-l;
  }
}

.address-input{
  [data-decidim-map]{
    margin-top: 1.5rem;
  }
}

.input-checkbox{
  @include custom-input("checkbox");

  input[type="checkbox"]{
    &:checked{
      + label{
        &::before{
          background-color: $hel-bus;
        }

        &::after{
          top: 7px;
          left: 5px;
          height: 13px;
          width: 17px;
          background-color: $white;
          clip-path: path("m0 7 1.5-1.5 4 4 9.5-9.5 1.5 1.5-11 11z");
        }
      }
    }
  }
}

.input-radio{
  @include custom-input("radio");

  input[type="radio"]{
    &:checked{
      + label{
        &::after{
          top: 8px;
          left: 6px;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: $hel-bus;
        }
      }
    }

    + label::before{
      border-radius: 50%;
    }
  }
}

.switch{
  padding: 2px;
  margin-bottom: $switch-margin;

  label{
    display: flex;
    flex-direction: column-reverse;
    gap: $hel-spacing-3-xs;
  }

  .switch-input{
    position: absolute;
    margin-bottom: 0;
    opacity: 0;

    &:checked{
      + .switch-paddle{
        background-color: $hel-bus;
        box-shadow: 0 0 0 2px $hel-bus;

        &::before{
          left: 100%;
          transform: translateX(-100%);
          background-color: $white;
          clip-path: path('m12 0c-6.6274 0-12 5.3726-12 12 0 6.6274 5.3726 12 12 12 6.6274 0 12-5.3726 12-12 0-6.6274-5.3726-12-12-12zm6 6.6667 2 2-10 10-6-6 2-2 4 4z');
        }
      }
    }
  }

  .switch-paddle{
    position: relative;
    display: inline-block;
    background: $white;
    box-shadow: 0 0 0 2px $hel-grayscale-90;
    border-left: 2px solid transparent;
    border-radius: 20px;
    cursor: pointer;
    height: 28px;
    width: 52px;
    inset: -2px 0;

    &::before{
      content: "";
      display: block;
      position: absolute;
      top: 2px;
      left: 0;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      transform: translateX(0);
      background-color: $hel-grayscale-90;
      clip-path: path('m12 0c-6.6274 0-12 5.3726-12 12 0 6.6274 5.3726 12 12 12 6.6274 0 12-5.3726 12-12 0-6.6274-5.3726-12-12-12zm4 6 2 2-4 4 4 4-2 2-4-4-4 4-2-2 4-4-4-4 2-2 4 4z');
      transition: all .2s;
    }
  }
}

.label__wrapper{
  display: flex;

  .label__text{
    flex: 1;
  }
}

.label-required{
  cursor: help;
  text-decoration: none;
  padding-right: 0;
  margin-left: 5px;

  > span{
    padding-right: 0;
    margin-right: 0;
  }
}

.help-text{
  display: block;
  margin-bottom: $form-spacing;
  font-weight: $global-weight-normal;

  &[class~="flex--sbc"]{
    display: flex;
  }
}

.help-text-form-required-fields{
  margin-top: 0;
}

.actions{
  margin-top: $form-spacing;
}
