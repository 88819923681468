.stepper{
  display: flex;
  margin: 1.4rem 0;

  &.stepper--vertical{
    flex-direction: column;

    &.stepper--small{
      .stepper__item{
        &::before,
        &::after{
          left: 8px;
        }
      }
    }

    .stepper__item{
      &::before,
      &::after{
        content: "";
        position: absolute;
        left: 15px;
        background-color: $hel-black;
        width: 2px;
        height: 50%;
      }

      &::before{
        top: 0;
      }

      &::after{
        bottom: 0;
      }

      .stepper__item__label{
        margin-left: 1.2rem;
      }
    }
  }

  &.stepper--small{
    .stepper__item{
      padding: .35rem 0;

      &:first-child{
        &::before{
          display: none;
        }
      }

      .stepper__item__indicator{
        width: 18px;
        height: 18px;
      }

      .stepper__item__label{
        position: relative;
        top: -4px;
        margin-left: .9rem;
        font-size: $hel-text-size-l;
      }
    }
  }

  &__item{
    position: relative;
    display: flex;
    padding: .6rem 0;
    list-style-type: none;

    &:first-child{
      padding-top: 0;
    }

    &:last-child{
      padding-bottom: 0;

      &::after{
        display: none;
      }
    }

    &.stepper__item--active{
      .stepper__item__indicator{
        background-color: $hel-black;
        color: $hel-white;
      }
    }

    &__indicator{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      width: 32px;
      height: 32px;
      background-color: $hel-white;
      color: $hel-black;
      border: 2px solid $hel-black;
      border-radius: 50%;
      font-weight: $global-weight-bold;
    }

    &__label{
      flex: 1;

      &__date{
        font-size: $hel-text-size-m;
        color: $hel-grayscale-60;
      }

      &__title{
        font-size: $hel-text-size-xl;
        font-weight: $global-weight-normal;
      }
    }
  }
}
