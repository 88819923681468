$margin-xs: $hel-spacing-xs;
$margin-s: $hel-spacing-s;
$margin-sm: $hel-spacing-m;
$margin-m: $hel-spacing-l;
$margin-l: $hel-spacing-xl;

//Sections

.section{
  margin-bottom: $margin-sm;
  word-wrap: break-word;

  @include breakpoint(large){
    margin-bottom: $margin-m;
  }
}

.m-bottom{
  margin-bottom: $margin-s;
}

// helpers
$size: (
  none: 0,
  xs: $margin-xs,
  s: $margin-s,
  sm: $margin-sm,
  m: $margin-m,
  l: $margin-l,
) !default;

$property: (
  p: padding,
  m: margin,
) !default;

$side: (
  t: top,
  r: right,
  b: bottom,
  l: left,
) !default;

@mixin make-spaces(){
  @each $p-key, $prop in $property{
    @each $v-key, $value in $size{
      .#{$p-key}-#{$v-key}{
        #{$prop}: #{$value} !important;
      }

      @each $s-key, $side in $side{
        .#{$p-key}#{$s-key}-#{$v-key}{
          #{$prop}-#{$side}: #{$value} !important;
        }
      }
    }
  }
}

@include make-spaces();
