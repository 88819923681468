.step-bullets{
  margin-bottom: 3rem;
}

.step-bullet{
  &.completed{
    .step-bullet__icon{
      border-color: $step-completed-color;
      color: $step-completed-color;
    }
  }

  .step-bullet__icon{
    position: relative;
    width: 6em;
    height: 6em;
    margin: 0 auto 1rem;
    border: 4px solid $step-pending-color;
    border-radius: 50%;
    color: $step-pending-color;

    .icon{
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      width: 3em;
      height: 3em;
    }
  }
}
