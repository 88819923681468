.intro-section{
  position: relative;

  .intro-section-bg{
    position: relative;
    z-index: 1;
    padding: 0;
    background-color: $hel-engel-medium-light;
  }

  .koro{
    z-index: 1;

    svg{
      fill: $hel-engel-medium-light;
    }
  }

  .intro-section-text{
    padding-top: 2rem;
    padding-bottom: 1.5rem;
    font-size: $hel-text-size-l;

    @include breakpoint(large){
      padding-top: 5.4rem;
      padding-bottom: 4.9rem;
    }
  }

  .intro-section-image{
    .image-wrapper{
      position: relative;
      display: flex;
      height: 100%;
      align-items: flex-start;

      @include breakpoint(medium){
        padding-top: 5.5rem;
      }

      @include breakpoint(xlarge){
        padding-top: 3.5rem;
      }
    }

    img{
      @include breakpoint(large){
        border: 14px solid $hel-white;
      }
    }
  }

  .intro-section-image-mobile{
    position: relative;
    top: -30px;
  }
}

.home-section{
  padding: 3.5rem 0;

  h2{
    margin-bottom: 2.9rem;
  }

  .section-image{
    margin-bottom: 2rem;
  }

  .more-link{
    margin-top: 3.2rem;
    font-size: $hel-text-size-l;
  }
}

.map-section{
  padding: 0;
  font-size: $hel-text-size-l;
  line-height: $hel-lineheight-l;

  h2{
    margin-bottom: 1.4rem;
  }

  .map-section-image{
    @include breakpoint(large){
      padding-right: 2.5rem;
    }

    svg{
      max-width: 100%;
    }
  }

  .map-section-text{
    margin-bottom: 2.4rem;
  }
}

.callout-section{
  padding: .9rem 0 2.9rem;

  @include breakpoint(large){
    padding: 3.1rem 0 2.9rem;
  }
}

.content-section,
.listing-section,
.comments-section{
  padding-bottom: 7rem;

  @include breakpoint(medium){
    padding-bottom: 10rem;
  }
}

.content-section{
  padding-top: 2rem;
}

.cards-section{
  padding: 3rem 0;
}

.cards-index-section{
  margin-top: 1.6rem;
  margin-bottom: 5rem;

  .column,
  .columns{
    display: flex;
    flex-direction: column;

    .card{
      flex: 1;
    }
  }
}

.listing-section{
  padding-top: 2.3rem;
  background-color: $hel-silver-light;

  @include breakpoint(medium){
    padding-top: 2rem;
  }

  .banner{
    margin-bottom: 1.9rem;
  }
}

.details-section{
  margin-top: 1.7rem;
  margin-bottom: 1.7rem;
}

.divider-section{
  margin-top: 3.8rem;
  margin-bottom: 4.7rem;
  padding: 3.6rem 0 2.65rem;

  .divider-section__title{
    margin-bottom: 2rem;
  }
}

.subtitle-section{
  margin-top: 3.5rem;
  margin-bottom: 3rem;

  h2{
    margin-bottom: 2.7rem;
  }
}
