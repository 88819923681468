/*
 * This file contains some overrides for the Decidim styles as well as custom
 * functionality for the Helsinki instance that is not related directly to the
 * Decidim platform. This customizes the platform look and feel to the Helsinki
 * design system in addition to the customized Foundation default styling.
 */

// Utils
@import "stylesheets/helsinki/util/link";
@import "stylesheets/helsinki/util/unit";
@import "stylesheets/helsinki/util/color";
@import "stylesheets/helsinki/util/icon";
@import "stylesheets/helsinki/util/flex";
@import "stylesheets/helsinki/util/margin";

// Modules
@import "stylesheets/helsinki/modules/typography";
@import "stylesheets/helsinki/modules/a11y";
@import "stylesheets/helsinki/modules/attachments";
@import "stylesheets/helsinki/modules/asset";
@import "stylesheets/helsinki/modules/table";
@import "stylesheets/helsinki/modules/lists";
@import "stylesheets/helsinki/modules/layout";
@import "stylesheets/helsinki/modules/grid";
@import "stylesheets/helsinki/modules/header";
@import "stylesheets/helsinki/modules/footer";
@import "stylesheets/helsinki/modules/menu";
@import "stylesheets/helsinki/modules/tabs";
@import "stylesheets/helsinki/modules/icons";
@import "stylesheets/helsinki/modules/koros";
@import "stylesheets/helsinki/modules/rich-text";
@import "stylesheets/helsinki/modules/callout";
@import "stylesheets/helsinki/modules/banner";
@import "stylesheets/helsinki/modules/label";
@import "stylesheets/helsinki/modules/buttons";
@import "stylesheets/helsinki/modules/forms";
@import "stylesheets/helsinki/modules/controls";
@import "stylesheets/helsinki/modules/reveal";
@import "stylesheets/helsinki/modules/upload";
@import "stylesheets/helsinki/modules/cards";
@import "stylesheets/helsinki/modules/toggle";
@import "stylesheets/helsinki/modules/bubble";
@import "stylesheets/helsinki/modules/tooltip";
@import "stylesheets/helsinki/modules/hero";
@import "stylesheets/helsinki/modules/sections";
@import "stylesheets/helsinki/modules/panels";
@import "stylesheets/helsinki/modules/stepper";
@import "stylesheets/helsinki/modules/phases";
@import "stylesheets/helsinki/modules/breadcrumbs";
@import "stylesheets/helsinki/modules/pagination";
@import "stylesheets/helsinki/modules/filters";
@import "stylesheets/helsinki/modules/slider";
@import "stylesheets/helsinki/modules/statistics";
@import "stylesheets/helsinki/modules/charts";
@import "stylesheets/helsinki/modules/datalabel";
@import "stylesheets/helsinki/modules/content";
@import "stylesheets/helsinki/modules/resource";
@import "stylesheets/helsinki/modules/linked-resources";
@import "stylesheets/helsinki/modules/attributes";
@import "stylesheets/helsinki/modules/comments";
@import "stylesheets/helsinki/modules/account";
@import "stylesheets/helsinki/modules/map";
@import "stylesheets/helsinki/modules/embed";
@import "stylesheets/helsinki/modules/accordion";
@import "stylesheets/helsinki/modules/voting";

//Additional elements
@import "stylesheets/helsinki/modules/omnipresent-banner";
@import "stylesheets/helsinki/modules/emoji-picker";

// Print
@import "stylesheets/helsinki/print";
