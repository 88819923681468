@mixin menu-horizontal{
  flex-direction: row;
  margin-left: -$menu-items-padding;
  margin-right: -$menu-items-padding;

  li{
    margin-bottom: 0;
    margin-left: $hel-spacing-s;

    &:first-child{
      margin-left: 0;
    }

    &.is-dropdown-submenu-parent{
      a{
        padding: 0;
      }
    }
  }
}

@mixin menu-vertical{
  flex-direction: column;
  margin-left: 0;
  margin-right: 0;

  li{
    margin-left: 0;
  }
}

.menu{
  display: flex;
  list-style-type: none;
  // line-height: $hel-lineheight-m;
  line-height: $hel-lineheight-l;
  margin: 0;

  @include plain-text-links;

  li{
    position: relative;
    padding-left: 0;

    &[data-submenu]{
      display: flex;
      flex-wrap: wrap;

      a{
        flex: 1;
      }

      .submenu{
        flex-basis: 100%;
      }
    }

    a{
      display: block;
      padding: $menu-items-padding;
    }

    .menu-link__toggle{
      flex: 0 0 64px;
      line-height: 0;
      border-left: 1px solid $hel-silver;
      border-bottom: 1px solid $hel-silver;

      @include breakpoint(large){
        position: absolute;
        right: 8px;
        flex: 0 0 0;
        border: 0;
        height: auto;
        align-self: center;
        margin-left: 4px;
      }

      &[aria-expanded="true"]{
        .icon{
          transform: rotate(180deg);
        }
      }

      .icon{
        width: 1.334em;
        height: 1.334em;
        transition: transform .2s ease-in;
      }
    }
  }

  &.vertical{
    @include menu-vertical;

    &.striped{
      &.large{
        font-size: $hel-text-size-xl;
      }

      li{
        &.is-active{
          font-weight: $global-weight-medium;

          a{
            border-left-color: $primary;
          }
        }

        a{
          padding: 1.1rem .8rem 1rem;
          border-bottom: 1px solid $hel-grayscale-20;
          border-left: 4px solid transparent;
        }
      }
    }
  }

  &.horizontal{
    @include menu-horizontal;
  }

  &.large-horizontal{
    @include breakpoint(large){
      @include menu-horizontal;
    }
  }

  &.nested{
    margin-left: $hel-spacing-s;

    li:last-child{
      margin-bottom: 0;
    }
  }

  &.submenu{
    display: none;
    background: $body-background;

    &.vertical{
      a{
        display: block;
      }
    }

    &.open{
      display: block;

      @include breakpoint(large){
        position: absolute;
        border: 1px solid $hel-grayscale-20;
        z-index: 100;
        top: 100%;
        left: -20px;
        min-width: 310px;
        width: calc(100% + 64px);
      }
    }

    li{
      margin-bottom: 0;
    }
  }

  &.dropdown{
    .is-dropdown-submenu-parent{
      > button{
        cursor: pointer;
      }
    }
  }

  &.accordion-menu{
    &.select{
      position: relative;

      > .is-accordion-submenu-parent > .menu.nested{
        position: absolute;
        top: calc(100% - 2px); // Over the outline
        width: 100%;
        background-color: $input-background;
        border: $input-border-focus;
        border-top: 1px solid $hel-grayscale-20;
      }

      a,
      button{
        padding: .9rem;
      }
    }

    .nested{
      margin-left: 0;

      .nested{
        margin-left: $hel-spacing-s;
      }

      .is-accordion-submenu-parent{
        > a,
        > button{
          @include select-arrow;

          padding-right: 2.5rem;
        }
      }
    }
  }

  // Dropdown menu that looks like a `<select>` element.
  &.dropdown.select,
  &.accordion-menu.select{
    > .is-dropdown-submenu-parent,
    > .is-accordion-submenu-parent{
      width: 100%;

      @include breakpoint(medium){
        width: auto;
      }

      &.is-active{
        > a,
        > button{
          border: $input-border-focus;
          box-shadow: $input-shadow-focus;
        }
      }

      > a,
      > button{
        @include select-arrow;

        width: 100%;
        border: $input-border;
        transition: $input-transition;
        padding: .8rem 4.8rem .8rem $dropdownmenu-padding;
        background-position: right -3.9rem center;
        background-color: $input-background;
        font-size: $input-font-size;
        line-height: $input-line-height;
        text-decoration: none;
        text-align: left;

        @include breakpoint(medium){
          width: auto;
        }

        &:hover{
          border-color: $input-border-color-hover;
        }

        &:focus{
          border: $input-border-focus;
          box-shadow: $input-shadow-focus;
        }
      }
    }
  }
}

.sub-navigation{
  margin-bottom: 2rem;
}

.sub-navigation-nav{
  position: relative;
  z-index: 1;
  font-size: $hel-text-size-xl;

  .menu{
    font-weight: $global-weight-medium;

    &.accordion-menu{
      > .is-accordion-submenu-parent > .menu{
        overflow-y: auto;
        max-height: 100vh;
      }
    }

    a{
      text-decoration: underline;
      padding: .35rem 0;

      @include breakpoint(mediumlarge){
        padding: .6rem .85rem .6rem 0;
      }
    }

    .nested{
      @include breakpoint(mediumlarge){
        margin: .5rem 0 .2rem 2.5rem;
      }
    }
  }
}

@include foundation-dropdown-menu;
