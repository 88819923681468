// $table-border: 1px solid $hel-grayscale-50;
// $table-padding: rem-calc(8 10 10);
// $table-striped-background: $hel-grayscale-10;
// $table-head-background: $hel-bus;
// $table-head-color: $white;

table{
  border-collapse: collapse;
  width: 100%;

  &.striped{
    tbody{
      tr:nth-child(even){
        background-color: $table-striped-background;
      }
    }
  }

  &.mobile-collapse{
    thead{
      // Keep the header visible for screen readers
      position: absolute;
      left: -10000px;
      top: auto;
      width: 1px;
      height: 1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);

      @include breakpoint(medium){
        position: static;
        left: auto;
        top: auto;
        width: auto;
        height: auto;
        overflow: initial;
        clip: initial;
      }
    }

    thead,
    tbody,
    tfoot{
      display: block;

      @include breakpoint(medium){
        display: table-row-group;
      }

      tr{
        display: block;

        @include breakpoint(medium){
          display: table-row;
        }

        th,
        td{
          padding: .1rem .625rem;

          @include breakpoint(medium){
            padding: $table-padding;
            display: table-cell;
          }

          &:first-child{
            padding-top: .79rem;

            @include breakpoint(medium){
              padding-left: 0;
            }
          }

          &:last-child{
            padding-bottom: .79rem;

            @include breakpoint(medium){
              padding-right: 0;
            }
          }

          display: block;
        }
      }
    }
  }

  &.details-table{
    font-size: $hel-text-size-l;
  }

  thead,
  tbody,
  tfoot{
    tr{
      &.row--highlight{
        background-color: $hel-engel-medium-light;
      }
    }

    th{
      // background-color: $table-head-background;
      // color: $table-head-color;

      &.td{
        background-color: inherit;
        color: inherit;
        font-weight: $global-weight-normal;
      }
    }

    th,
    td{
      padding: $table-padding;

      &:first-child{
        padding-left: 0;
      }

      &:last-child{
        padding-right: 0;
      }
    }
  }

  thead,
  tfoot{
    th,
    td{
      font-weight: $global-weight-bold;
      text-align: #{$global-left};
    }
  }

  tbody{
    tr{
      border-bottom: $table-border;
      background-color: transparent;

      &:last-child{
        border-bottom: 0;
      }
    }

    th{
      text-align: left;
    }
  }
}

.card{
  .details-table{
    margin-bottom: 1.7rem;
  }
}
