.filters{
  padding: 1.7rem 0 1rem;

  @include breakpoint(medium){
    padding: 2.1rem 0 1rem;
  }
}

.filters__control{
  padding: .1rem 0;

  @include breakpoint(medium){
    padding: .4rem 0;
  }
}

.filters__actions{
  padding: .6rem 0;

  @include breakpoint(medium){
    padding: .4rem 0;
  }

  .button{
    margin-right: 1.6rem;

    &:last-child{
      margin-right: 0;
    }

    &.clear{
      padding-left: .5rem;
      padding-right: .5rem;
    }
  }
}

// The controls section
#additional_search{
  padding-top: 1rem;
  margin-bottom: 2rem;
}
