.social-register{
  margin-top: 4rem;
}

.reveal .social-register{
  margin-top: 0;
}

.account-wrapper{
  margin: 0 .5rem;
}

.register__separator{
  display: block;
  margin: 2rem 0;
  text-align: center;
}

.impersonation-warning{
  background-color: $hel-grayscale-5;
  border-bottom: 1px solid $black;
  text-align: center;
  padding: .5rem;

  .impersonation-warning__action{
    display: inline-block;
    margin-left: 1rem;

    .button{
      margin-bottom: 0;
    }
  }
}
