.asset{
  display: flex;
  background-color: $hel-silver-medium-light;
  padding: 1.35rem 1.9rem;
  font-size: 1.5rem;
  margin-bottom: .5rem;

  &__label{
    flex: 1;
  }

  &__icon{
    display: flex;

    .icon{
      width: 2.2rem;
      height: 2.2rem;
    }
  }

  .external-link-indicator{
    width: 1px;
    height: 1px;

    .icon{
      display: none;
    }
  }
}
