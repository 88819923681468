.koro{
  position: relative;
  bottom: -1px; // Prevent "shadow" border appearing
  width: 100%;
  line-height: 0;

  &.flip-vertical{
    transform: scaleY(-1);
    bottom: auto;
    top: -1px;
  }
}
