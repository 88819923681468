@use "sass:map";

$card-side-padding: 1.3rem;
$card-border-color: $hel-silver;
$card-border: 1px solid $card-border-color;

.card{
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 1rem;
  background-color: $white;
  text-decoration: none;

  &.card--padded{
    padding: 1.9rem $card-side-padding;

    &.card--hasfooter{
      padding-bottom: .5rem;
    }

    .card__header,
    .card__content,
    .card__footer,
    .card__section,
    .card__tags,
    .card__info,
    .card__details{
      padding: 0;
    }

    .card__main{
      display: flex;
      flex-direction: column;

      @include breakpoint(medium){
        flex: 1;
        width: auto;
      }
    }

    .card__footer{
      margin: 0 -.5rem;
      padding-top: .3rem;
    }
  }

  &.card--lead{
    font-size: $hel-text-size-l;

    .card__main{
      margin-bottom: .8rem;
      padding: .8rem .2rem;
    }

    .card__content{
      line-height: 1.3;
    }
  }

  &.card--border{
    border: $card-border;
  }

  &.card--full{
    @include breakpoint(medium){
      padding: 1.3rem 1.5rem;
      font-size: $hel-text-size-l;
    }

    h2{
      // font-size: 1.25rem;
      font-size: 1.5rem;

      @include breakpoint(medium){
        font-size: 1.5rem;
      }
    }

    .card__inner,
    .card__footer{
      display: flex;
    }

    .card__inner{
      flex-direction: column-reverse;

      @include breakpoint(medium){
        flex-direction: row;
      }
    }

    .card__main,
    .card__aside{
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .card__main{
      @include breakpoint(medium){
        flex: 1;
        width: auto;
      }
    }

    .card__aside{
      @include breakpoint(medium){
        width: 28.125%;
        margin-left: map.get($grid-column-gutter, "medium");
      }
    }

    .card__footer{
      flex-direction: column;
      align-items: flex-end;

      @include breakpoint(medium){
        flex-direction: row;
      }
    }

    .card__section,
    .card__info,
    .card__details{
      padding: 0;
    }

    .card__content{
      padding: 1.3rem $card-side-padding;

      @include breakpoint(medium){
        padding: 0;
      }
    }

    .card__footer{
      padding: 0 $card-side-padding;

      @include breakpoint(medium){
        padding: 0;
      }
    }

    .card__tags{
      padding: 1.4rem 0 1rem;
    }

    .card__status{
      padding: .55rem 0 0;

      @include breakpoint(medium){
        padding-top: 1.5rem;
      }
    }

    .card__info{
      flex-wrap: wrap;

      .card__info__item{
        flex: 0 0 50%;
        padding: .6rem 0;

        @include breakpoint(medium){
          flex: 0 0 auto;
          padding: 0;
          margin-right: 2.5rem;
        }

        &:last-child{
          margin-right: 0;
        }
      }
    }
  }

  // Used for notifications and conversations
  &.card--widget{
    border: $card-border;
    padding-bottom: 0;

    .card-data{
      display: flex;
      flex-wrap: inherit;
      list-style-type: none;
      margin: 0;

      .card-data__item{
        color: $body-font-color;
        text-transform: none;
        font-size: rem-calc(16);
        text-align: left;
        padding: $card-padding-small;

        @include modifiers(color);

        @include breakpoint(medium){
          padding: $card-padding;
        }

        &:first-child{
          flex-grow: 0;
          flex-basis: 12%;
          background-color: $card-secondary-bg;
          position: relative;

          > div{
            display: flex;
            flex-direction: column;
            align-items: center;

            @include absolute-center;
          }
        }

        &--expand{
          flex: 1;
        }

        .icon--large{
          width: 2rem;
          height: 2rem;
        }

        .mark-as-read-button{
          .icon{
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }
    }
  }

  &.bg-secondary{
    @extend .bg-secondary;
  }

  &.bg-tertiary{
    @extend .bg-tertiary;
  }

  .h4{
    line-height: $hel-lineheight-m;
  }

  // Basic section that is not typed in any way. Wraps the content with the same
  // side padding as other sections.
  & &__section{
    padding: 0 $card-side-padding;
  }

  // Main content section that should include the title and optional short
  // summary.
  & &__content{
    padding: 1.2rem $card-side-padding .7rem;

    @include breakpoint(medium){
      flex: 1;
    }

    &--nofill{
      @include breakpoint(medium){
        flex: 0;
      }
    }
  }

  & &__fill{
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include breakpoint(medium){
      flex: 1;
    }
  }

  // Tags section
  & &__tags{
    padding: .8rem $card-side-padding 2rem;
  }

  // Info items that are listed two per row, first one on the left and second
  // one on the right.
  & &__info{
    display: flex;
    flex-direction: row;
    padding: .4rem $card-side-padding 1rem;
    font-size: $hel-text-size-l;
    font-weight: $global-weight-medium;

    &__item{
      flex: 1;

      &:nth-child(2n){
        text-align: right;
      }
    }
  }

  // Details that display an icon and text next to the icon.
  & &__details{
    padding: 0 $card-side-padding .3rem;

    @include breakpoint(medium){
      flex: 1;
    }

    &__item{
      display: flex;
      margin-top: $hel-spacing-s;

      &:first-child{
        margin-top: 0;
      }

      .icon{
        position: relative;
        top: -.1rem;
        width: 1.5rem;
        height: 1.5rem;
        margin-right: .7rem;
      }

      &__text{
        flex: 1;
      }
    }
  }

  // Footer that can contain any extra actions on non-link cards, e.g. during
  // budgeting pipeline.
  & &__footer{
    padding: .3rem $card-side-padding;
  }

  // Status details that contain the card "statuses" (as they are named in
  // Decidim). Typically this contains the amount of comments and favorites for
  // the item represented by the card.
  & &__status{
    font-size: $hel-text-size-xl;
    padding: .3rem $card-side-padding;

    .card-data{
      display: flex;
      list-style-type: none;
      margin: 0;
      padding: 0;

      &__item{
        padding: 0;
        flex: 1;
        text-align: center;

        &:first-child{
          text-align: left;
        }

        &:last-child{
          text-align: right;
        }

        .icon{
          position: relative;
          top: .3rem;
          width: 1.5rem;
          height: 1.5rem;
          margin-right: .3rem;
        }
      }
    }
  }

  // Bottom card indicator e.g. external link icon for events or arrow icon for
  // news articles.
  & &__indicator{
    padding: .3rem $card-side-padding;
    margin-top: 1.3rem;

    .external-link-indicator{
      width: auto;
      height: auto;
      margin: 0;
    }

    .icon{
      width: 1.9rem;
      height: 1.9rem;
    }
  }

  .card--list__author{
    display: block;
    padding: .5rem 0;

    .author{
      margin: 0;
    }
  }
}

a.card{
  &:hover,
  &:focus{
    text-decoration: none;

    h2,
    h3,
    h4,
    h5,
    h6{
      text-decoration: underline;
    }
  }
}

.card-data__item__number{
  display: block;
}

.cards-grid{
  margin-top: 1.7rem;
  margin-bottom: 1rem;

  .column{
    // Stretches the cards to equal heights
    display: flex;
  }
}

.cards-list{
  margin-top: .6rem;
}
