.emoji{
  &__container{
    position: relative;
  }

  &__trigger{
    position: relative;
    top: -$form-spacing;
  }

  &__button{
    position: absolute;
    bottom: $global-margin * .5;
    right: $global-margin;
    cursor: pointer;
  }

  &__button svg{
    width: 1em;
  }
}
