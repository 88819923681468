.statistics-cell{
  display: grid;
  grid-template-columns: minmax(0, 1fr);

  @include breakpoint(smallmedium){
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include breakpoint(medium){
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include breakpoint(large){
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .statistic__data{
    padding: 1rem;
    text-align: center;

    @include breakpoint(medium){
      padding: 2rem 1rem;
    }
  }

  .statistic__title{
    font-weight: $global-weight-normal;
  }

  .statistic__number{
    @extend h3;
  }
}
