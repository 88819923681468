.iframe-embed{
  // 16:9 aspect ratio, we assume all iframe embeds are videos
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  background-color: #ececec;
  margin-bottom: $paragraph-margin-bottom;

  .ql-video{
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.disabled-iframe{
  margin-bottom: $paragraph-margin-bottom;
}

.ql-toolbar .ql-video{
  margin-top: 0;
  margin-bottom: 0;
}
